import { useState, useContext, useCallback, useEffect } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import { ReactComponent as Opensea } from "./opensea.svg";
import Gifer from "./gifer.gif";
import { ReactComponent as Twitter } from "./twitter.svg";

function App() {
  const [count, setCount] = useState(1);
  const [mintedCount, setMintedCount] = useState(0);
  const {
    connectBrowserWallet,
    mint,
    userData,
    transactionHash,
    contractDetails,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  useEffect(() => {
    if (!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)));
    }
  }, [contractDetails]);

  return (
    <>
      <div className="greed-container">
        <div className="greed-wrapper">
            <a
              href="https://opensea.io/collection/alpha-goblins"
              target="_blank"
              rel="noreferrer"
              className="greed-link-item"
            >
              <Opensea />
            </a> 
            <a
              href="https://twitter.com/AlphaGoblins"
              target="_blank"
              rel="noreferrer"
              className="greed-link-item greed-twitter"
            >
              <Twitter />
            </a>
        </div>
        <div className="greed-form">  
          <h2>FREE 1 MINT</h2>
          {!!contractDetails && (
            <p className="counter">
              {mintedCount} / 4444
            </p>
          )} 
          <button
              type="button"
              className="connect-wallet"
              disabled
            >
              Connect Wallet
            </button>
          <img src={Gifer} alt="" />


          <p>greEdy oR nmot, goBlinS dOn't giVe ah fyck aBout Other gobLiNs gO graAab as <br/> MannY u wantT priCe is cHEApsy as shiT 0.0044</p>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
